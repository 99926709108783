import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';

const HistorySequence = ({ name, status, creationDate, endDate }) => {
  // Definir colores y estilos para el Chip del estatus
  const getStatusChipColor = (status) => {
    switch (status) {
      case 'Pendiente':
        return { color: 'warning', label: 'Pendiente' };
      case 'Terminado':
        return { color: 'success', label: 'Terminado' };
      default:
        return { color: 'default', label: status };
    }
  };

  const statusChip = getStatusChipColor(status);

  return (
    <Card sx={{ maxWidth: 400, margin: 'auto', mt: 5, boxShadow: 4, borderRadius: 2 }}>
      <CardContent sx={{ padding: '24px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="div" fontWeight="bold">
            {name}
          </Typography>
          <Chip 
            label={statusChip.label} 
            color={statusChip.color} 
            size="small"
            sx={{ fontWeight: 'bold' }}
          />
        </Box>
        
        <Divider sx={{ mb: 2 }} />

        <Box display="flex" alignItems="center" mb={1}>
          <EventIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Fecha de Creación: {new Date(creationDate).toLocaleString()}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <AccessTimeIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Fecha de Término: {new Date(endDate).toLocaleString()}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ padding: '16px', justifyContent: 'flex-end' }}>
  
      </CardActions>
    </Card>
  );
};

export default HistorySequence;
