import { Add, Delete, Done, Navigation, WhatsApp } from "@mui/icons-material";
import { Button, Checkbox, Chip, Fab, Grid, IconButton, Link, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import api from "../../services/api";
import CallModal from "../CallModal";
import CustomFilter from "../CustomFilter";
import MassiveMsgModal from "../MassiveMsgModal";
import MultiSelect from "../MultiSelect";
import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";

import { SocketContext } from "../../services/socket";
import DrivePicker from "../DrivePicker";
import GoogleLoginView from "../GoogleLogin";
import MoveModal from "../MoveModal";
import BookFooter from "../BookFooter";
import { GridFooterContainer } from "@mui/x-data-grid";
import { GridFooter } from "@mui/x-data-grid";
import SendMassiveMailModal from "../SendMassiveMailModal";
import Loading from "../Loading";
import ClientInfoModal from "../ClientInfoModal";
import NewMessageModal from "../NewMessageModal";
import NewQuote from "../NewQuote";
import QuotesModal from "../QuotesModal/Index";



const DatabaseTable = ({ theme, advisers, templateList, currentAdviserID, showAdviserButton, groupID, setShowAddClientButton, addClientToDB, setAddClientToDB, reloadSheet, setCurrentSheet, loadAllClients = false }) => {


    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [tags, setTags] = useState([]);

    const [userId, setUserId] = useState(0);

    const [filters, setFilters] = useState({});


    const [open, setOpen] = useState(false);
    const [openCallModal, setOpenCallModal] = useState(false);
    const [openMove, setOpenMove] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    /**
      * Notifiaciones
      */
    const { enqueueSnackbar } = useSnackbar();


    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    const [showAddAdvisers, setShowAddAdvisers] = useState(false);
    const [selectedAdvisers, setSelectedAdvisers] = useState([]);

    const [reload, setReload] = useState(false);

    const [reload2, setReload2] = useState(false);

    const [scheduleMsg, setScheduleMsg] = useState(false);

    const [isSequence, setIsSequence] = useState(false);

    const [scheduleCall, setScheduleCall] = useState(false);

    const [currentPermissions, setCurrentPermissions] = useState([]);

    const [currentDatabaseID, setCurrentDatabaseID] = useState(null);

    const [reloadClients, setReloadClients] = useState(false);

    const [status, setStatus] = useState([]);

    const [products, setProducts] = useState([]);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [maxCount, setMaxCount] = useState(0);

    const [lastPage, setLastPage] = useState(0);
    const [lastPageSize, setLastPageSize] = useState(20);

    const [resetClients, setResetClients] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    const [selectedClient, setSelectedClient] = useState({})

    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    /**
  * Compara la lista de permisos con los permisos asignados
  */
    const revisaPermisos = (allow, minMatch = 1) => {
        let count = 0;

        for (let i = 0; i < allow.length; i++) {
            for (let j = 0; j < currentPermissions.length; j++) {

                if (allow[i] == currentPermissions[j]) {
                    count++;
                }
            }
        }

        return count >= minMatch;
    }


    useEffect(() => {



        async function getStatus() {


            const { data } = await api.post("api/getStatus");

            if (data.statusList.length > 0) {

                setStatus(data.statusList);

            }

        }

        async function getProducts() {


            const { data } = await api.post("api/getProducts");

            if (data?.products) {

                setProducts(data.products);

            }

        }

        async function getTagsService() {

            try {


                try {

                    const { data } = await api.post("api/getTags");

                    if (data.tags) {
                        setTags(data.tags);
                    }

                } catch (err) {

                    console.log(err);
                }

            } catch (err) {
                console.log(err);
            }


        }

        async function getUserId() {

            try {
                const userInfo = await api.post("/api/getPermissions");
                setUserId(userInfo.data.userInfo.id);
                setCurrentPermissions(userInfo.data.userInfo.permissions);
            } catch (err) {
                console.log(err);
            }

        }
        getUserId();
        getTagsService();
        getStatus();
        setClients([]);
        getProducts();

    }, [currentAdviserID, reload, groupID])

    useEffect(() => {
        async function getClients(databseID) {

            let clientsTemp = clients;

            if (lastPageSize != pageSize) {
                clientsTemp = [];
                setLastPageSize(pageSize);
            }

            if (resetClients) {
                setIsLoading(true);
                clientsTemp = [];
                setResetClients(false);
            }
            let filter = currentAdviserID ? { adviser: currentAdviserID } : {};
            filter.filters = filters;
            if (databseID) {
                filter.filters = { ...filters, groupId: databseID };
            }

            filter.page = lastPage;
            filter.pageSize = pageSize ? pageSize : 20;



            const { data } = await api.post("api/getClients", filter);


            setClients([...clientsTemp, ...data.clients]);
            setMaxCount(data.count);
            setShowAddClientButton(true);
            setIsLoading(false);

        }
        if (currentDatabaseID && !loadAllClients) {

            getClients(currentDatabaseID);
            setCurrentSheet(currentDatabaseID)
        }

    }, [currentDatabaseID, reloadClients, lastPage, pageSize, filters])

    useEffect(() => {
        async function getClients() {

            let filter = currentAdviserID ? { adviser: currentAdviserID } : {};


            const { data } = await api.post("api/getAllClients", filter);


            setClients(data.clients);

        }
        if (loadAllClients) {
            getClients();

            if (setCurrentSheet) {
                setCurrentSheet(null)
            }

        }

    }, [loadAllClients])


    useEffect(() => {

        setReload2(reloadSheet);
    }, [reloadSheet])



    /**
     *  Función para filtrar la información
     */
    const filterData = async (field, value) => {
        let tempFilters = { ...filters, [field]: value };


        if (currentAdviserID) {
            tempFilters.adviser = currentAdviserID;
        }

        if (currentDatabaseID) {
            tempFilters.groupId = currentDatabaseID;
        }

        try {
            setResetClients(true);

        } catch (err) {
            alert("Ocurrio un error al filtrar la información");
        }

        handlePageSizeChange();
        setFilters(tempFilters);

    }
    /**
     * Recupera un arreglo con los telefonos
     * @returns 
     */
    const getPhones = () => {
        let phones = [];

        selectedClients.map((cl) => {
            phones.push(cl.phone);
        })

        return phones;
    }

    /**
     * Recupera un arreglo con los correos
     * @returns 
     */
    const getMails = () => {
        let emails = [];

        selectedClients.map((cl) => {
            emails.push(cl.email);
        })

        return emails;
    }


    const callMassive = async (currentTemplate, name, phone) => {

        /**
        * Array de contactos para enviar los mensajes
        * Integrantes del grupo que se creará
        */
        const constactsArr = [...selectedClients];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }

        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
            kind: 'Llamada',
            isMedia: true,
            templateId: currentTemplate.id,
            body: '',
            mediaUrl: currentTemplate.filePath,
            userSend: userId,
            twilioPhone: phone,
            delay: 1 * 1000,
            status: 'Pendiente',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);

            //  console.log(data);
            if (data.status == "Success") {

                enqueueSnackbar(`Se estan enviando ${constactsArr.length} llamadas`, {
                    variant: 'success',
                });

                const socketObj = {
                    id: data.msgObj.id,
                    from: phone,
                    contacts: constactsArr,
                    delay: 1000,
                }


                /**
                 * Id del envio masivo
                 */

                socket.emit("sendMassiveCall", socketObj);

                setOpenCallModal(false);

            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }

        setOpen(false)
    }

    const setAdviserToClients = async () => {

        let clientList = [...selectedClients];

        clientList.map((cl) => {
            delete cl.Tags;
            delete cl.Comments;
            delete cl.User;
        })


        try {
            await api.post('api/createPage', {
                userId: userId,
                clients: clientList,
                advisers: selectedAdvisers
            });

            enqueueSnackbar(`La asignación fue exitosa`, {
                variant: 'success',
            });

            setReload(!reload);
            setSelectedClients([]);
            setSelectedAdvisers([])
            setShowAddAdvisers(false);

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al asignar los clientes`, {
                variant: 'error',
            });
        }


    }


    /**
   * Programa las llamadas masivas
   */
    const scheduleCallMassive = async (currentTemplate, name, phone, sendAt) => {

        /**
            * Array de contactos para enviar los mensajes
            * Integrantes del grupo que se creará
            */
        const constactsArr = [...selectedClients];

        if (!name) {
            enqueueSnackbar(`El nombre es requerido`, {
                variant: 'error',
            });
            return false;
        }


        /**
         * Objeto para crear un grupo de envio (sin integrantes)
         */
        const msgObj = {
            name: name,
            forwardingId: 0,
            wpId: 'Twilio',
            kind: 'Llamada',
            isMedia: true,
            body: '',
            mediaUrl: currentTemplate.filePath,
            templateId: currentTemplate.id,
            userSend: userId,
            twilioPhone: phone,
            delay: 1 * 1000,
            status: 'Programado',
            totalMessagesSend: 0,
            totalMessagesLost: 0,
            totalMessages: constactsArr.length
        }


        try {


            /**
             * Crea el grupo de envio (sin integrantes aún)
             */
            const request = { messageObj: msgObj, words: [] };

            //console.log(request);
            /** ------------------------------------------------------------ */
            const { data } = await api.post('/api/massive/create', request);
            //  console.log(data);
            if (data.status == "Success") {

                /**
                 * Id del envio masivo
                 */
                let idMsgEnv = data.msgObj.id;

                try {

                    const request = {
                        msgObject: {
                            name: name,
                            delay: 1 * 1000,
                            status: 'Programado',
                            type: 'Llamada',
                            user: userId,
                            idMassive: idMsgEnv,
                            idTemplate: currentTemplate.id,
                            sendAt: sendAt
                        },
                        contacts: constactsArr
                    }

                    const response = await api.post('/api/createSchedule', request);

                    if (response.data.status == "Success") {
                        enqueueSnackbar(`Se programaron los mesajes para la fecha ${sendAt}`, {
                            variant: 'success',
                        });
                    } else {
                        enqueueSnackbar(`Error al programar el mensaje`, {
                            variant: 'error',
                        });
                    }
                } catch (err) {
                    enqueueSnackbar(`Error al programar el mensaje`, {
                        variant: 'error',
                    });
                }


                setOpenCallModal(false)
            } else {
                enqueueSnackbar(`Error al crear el grupo de envio`, {
                    variant: 'error',
                });
            }


        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al enviar mensajes`, {
                variant: 'error',
            });
        }


    }

    /**
     * Revisa el tipo de envio que se debe hacer
     */
    const checkSendType = (currentTemplate, name, phone, sendAt) => {

        if (scheduleCall) {
            scheduleCallMassive(currentTemplate, name, phone, sendAt);
        } else {
            callMassive(currentTemplate, name, phone)
        }
    }

    /**
     * Mueve los datos seleccionados a una nueva base de datos
     * @param {*} newDatabaseId 
     */
    const moveDataToDatabase = async (newDatabaseId) => {

        try {
            const { data } = await api.post('/api/moveGroupData', { newID: newDatabaseId, oldID: groupID, clients: selectedClients })

            if (data.status == "Success") {
                enqueueSnackbar(`Se movieron ${selectedClients.length} clientes`, {
                    variant: 'success',
                });

                setReload(!reload);
            } else {
                throw data;
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al mover los clientes`, {
                variant: 'error',
            });
        }

        setOpenMove(false);
    }


    const CustomFooter = () => {
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <BookFooter />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GridFooterContainer>
                        {/* Add what you want here */}
                        <GridFooter sx={{
                            border: 'none', // To delete double border.
                        }} />
                    </GridFooterContainer>
                </Grid>

            </Grid>
        );
    }


    const updateClient = async (id, client) => {
        try {
            const { data } = await api.post('/api/updateClient', { id: id, client: client });


            if (data.status == "Success") {
                enqueueSnackbar(`Se actualizó el cliente`, {
                    variant: 'success',
                });


            } else {
                console.log(data);
                throw data;
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Error al actualziar el cliente`, {
                variant: 'error',
            });
        }
    }
    /**
     * Vista para la edición de un campo simple
     * @param {*} props 
     * @returns 
     */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        const [newValue, setNewValue] = useState(value)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            setNewValue(target.value)
        }

        /**
         * Cuando presiona el botón se guarda el nuevo valor
         */
        const handleCommit = async (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();



            /**
             * Para los objetos dentro de la membresía excluyendo el id
             * se actualiza sin problema
             */

            props.row[props.field] = newValue;

            let request = { [props.field]: newValue };

            await updateClient(props.row.id, request);


        };



        return (
            <Grid container sx={{ width: '100%' }} >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        defaultValue={value}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} >
                    <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                        <Done color="success" />
                    </IconButton>
                </Grid>
            </Grid>
        )

    }

    const handlePageChange = (newPage) => {
        if (newPage > lastPage) {
            setLastPage(lastPage + 1)
        }

        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        setLastPageSize(pageSize);
        setPageSize(newPageSize);
        setPage(0); // Reinicia la página al cambiar el tamaño de página
        setLastPage(0)
    };

    const handleDeleteTag = async (clientId, idTags) => {
        try {
            await api.post('/api/removeTagFromClient', { clientId, idTags })
        } catch (err) {

        }
        setReloadClients(!reloadClients)
        enqueueSnackbar(`Etiqueta eliminada`, {
            variant: 'success',
        });
    }
    return (
        <>
            <Loading open={isLoading} />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"

            >

                <Grid
                    container
                    sx={{ height: '80vh', minWidth: '100%' }}
                    alignItems="center"
                    justifyContent="center"
                    justify="center">

                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="center"
                        justify="center"
                        spacing={4}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >

                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                            justify="center"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                        >
                            <Grid
                                item
                            >
                                <Typography fontSize={24}> Filtros avanzados</Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Checkbox
                                    checked={showAdvancedFilters}
                                    onChange={(e) => { setShowAdvancedFilters(e.target.checked) }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {showAdvancedFilters &&
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="center"
                            justify="center"
                            spacing={4}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                        >


                            <Grid
                                item
                            >
                                <CustomFilter title={"Nombre"} field={"firstName"} filterFunc={filterData} />
                            </Grid>
                            <Grid
                                item
                            >
                                <CustomFilter title={"Correo"} field={"email"} filterFunc={filterData} />
                            </Grid>
                            <Grid
                                item
                            >
                                <CustomFilter title={"Teléfono"} field={"phone"} filterFunc={filterData} />
                            </Grid>
                            <Grid
                                item

                            >
                                <CustomFilter isSelect keys={["id", "name"]} list={tags} title={'Etiquetas'} field={'tags'} filterFunc={filterData} />
                            </Grid>
                            <Grid
                                item

                            >
                                <CustomFilter isSelect keys={["id", "name"]} list={status} title={"Estatus"} field={"status"} filterFunc={filterData} />
                            </Grid>

                            <Grid
                                item

                            >
                                <CustomFilter isSelect keys={["id", "name"]} list={products} title={"Producto"} field={'product'} filterFunc={filterData} />
                            </Grid>
                        </Grid>
                    }
                    <Grid item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                    >
                        {!loadAllClients && <BookFooter reload={reload2} setReloadClients={setReloadClients} reloadClients={reloadClients} bookID={groupID} onPageChange={(id) => { setResetClients(true); handlePageSizeChange(); setCurrentDatabaseID(id) }} addClientToDB={addClientToDB} setAddClientToDB={setAddClientToDB} />}
                    </Grid>
                    <Grid item
                        xs={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        sm={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        md={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        lg={showAddAdvisers && selectedClients.length > 0 ? 6 : 12}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: '100%' }}>
                        <TablaUsuarios
                            page={page}
                            pageSize={pageSize}
                            totalRows={maxCount}
                            showPages
                            handlePageSizeChange={handlePageSizeChange}
                            handlePageChange={handlePageChange}
                            bookID={groupID}
                            // onPageChange={(id) => { setCurrentDatabaseID(id) }}
                            rows={clients}
                            columns={[
                                {
                                    headerName: 'Fecha de subida', field: 'createdAt', hide: false, flex: 1,
                                    renderCell: (params) => {

                                        return (
                                            <Grid container>
                                                <Grid item md={10}>
                                                    {params.row?.createdAt.split(" ")[0]}
                                                </Grid>

                                            </Grid>
                                        )
                                    }

                                },
                                {
                                    headerName: 'Última actualización', field: 'updatedAt', hide: false, flex: 1,
                                    renderCell: (params) => {

                                        return (
                                            <Grid container>
                                                <Grid item md={10}>
                                                    {params.row?.updatedAt.split(" ")[0]}
                                                </Grid>

                                            </Grid>
                                        )
                                    }

                                },
                                {
                                    headerName: 'Detalle', field: 'Ver información', hide: false,
                                    renderCell: (params) => {
                                        return <Button variant="contained" onClick={
                                            () => {

                                                if (params.row.Chat) {
                                                    setSelectedClient({
                                                        ...params.row.Chat,
                                                        Client: params.row
                                                    })
                                                    setOpenInfo(true)
                                                } else {
                                                    setSelectedClient({
                                                        Client: params.row
                                                    })
                                                    setOpenInfo(true)
                                                }

                                            }}>Ver detalle</Button>
                                    },
                                },
                                {
                                    headerName: 'Cotizacion', field: 'crearCotizacion', hide: false,
                                    renderCell: (params) => {
                                        return <NewQuote client={params.row} variant="contained" type="Cotización"/>
                                    },
                                },
                                {
                                    headerName: 'Factura', field: 'crearFactura', hide: false,
                                    renderCell: (params) => {
                                        return <NewQuote client={params.row} variant="contained" type="Factura"/>
                                    },
                                },
                                {
                                    headerName: 'Cotizaciones', field: 'verCotizacion', hide: false,
                                    renderCell: (params) => {
                                        return <>
                                            {
                                                params.row.Quotes?.length ?
                                                    <QuotesModal clientID={params.row.id} variant="contained" />
                                                    :
                                                    "Sin cotizaciones"
                                            }
                                        </>
                                    },
                                },
                                {
                                    headerName: 'Facturas', field: 'verFActuras', hide: false,
                                    renderCell: (params) => {
                                        return <>
                                            {
                                                params.row.Quotes?.length ?
                                                    <QuotesModal clientID={params.row.id} variant="contained" type="Facturas"/>
                                                    :
                                                    "Sin facturas"
                                            }
                                        </>
                                    },
                                },
                                {
                                    headerName: 'Etiquetas', field: 'tags', hide: false, flex: 1,
                                    /*renderHeader: (props) => {
                                        return <CustomFilter isSelect keys={["id", "name"]} list={tags} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderCell: (params) => {

                                        return (
                                            <Grid md={12} sx={{ height: '100%' }} direction="column"
                                                alignItems="center"
                                                justifyContent="center">
                                                {params.row.Tags?.map((value) => (
                                                    <Chip onDelete={() => handleDeleteTag(params.row.id, [value.id])} label={value.name} sx={{ color: 'white', background: value?.color ? value.color : 'black' }} deleteIcon={<Delete style={{ color: 'white' }} />} />
                                                ))}
                                            </Grid>
                                        )
                                    }

                                },
                                {
                                    headerName: 'Estatus', field: 'status', hide: false, flex: 1,
                                    /** Filtro personalizado */
                                    /*renderHeader: (props) => {
                                        return <CustomFilter isSelect keys={["id", "name"]} list={status} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderCell: (params) => {
                                        return <Chip label={params.row?.Status?.name ? params.row.Status.name : 'Sin estatus'} sx={{ color: 'white', background: params.row?.Status?.color ? params.row.Status.color : 'black' }} />
                                    }

                                },
                                {
                                    headerName: 'Nombre', field: 'firstName', hide: false, editable: true, flex: 1,
                                    /*renderHeader: (props) => {
                                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderEditCell: (props) => <SimpleEditField {...props} />
                                },


                                /* {
                        headerName: 'Pais', field: 'country', hide: false,
                                     renderHeader: (props) => {
                                         return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Ciudad', field: 'city', hide: false,
                                     renderHeader: (props) => {
                                         return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Género', field: 'gender', hide: false,
                                  
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Hombre", key: "Hombre" }, { value: "Mujer", key: "Mujer" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Asesor', field: 'adviser', hide: false,
                                
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["id", "firstName"]} list={advisers} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                     renderCell: (params) => {
 
                                         return (
                    <Grid md={12}>
                        {params.row.User?.firstName ? params.row.User.firstName : "Sin asesor asignado"}
                    </Grid>
                    )
                                     }
 
                                 },
                    {
                        headerName: 'Nivel educativo', field: 'level', hide: false,
                                    
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Profesional", key: "Profesional" }, { value: "Sin profesión", key: "Sin profesión" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Teléfono', field: 'phone', hide: false,
                                  
                                     renderHeader: (props) => {
                                         return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
 
                                 },
                    {
                        headerName: 'Estatus Llamada 1', field: 'statusLlamada0', hide: false,
                                    
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Estatus Llamada 2', field: 'statusLlamada1', hide: false,
                                   
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Estatus Llamada 3', field: 'statusLlamada2', hide: false,
                                   
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Estatus Mensaje 1', field: 'statusMensaje0', hide: false,
                                   
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Estatus Mensaje 2', field: 'statusMensaje1', hide: false,
                                    
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },
                    {
                        headerName: 'Estatus mensaje 3', field: 'statusMensaje2', hide: false,
                                    
                                     renderHeader: (props) => {
                                         return <CustomFilter isSelect keys={["key", "value"]} list={[{ value: "Atendido", key: "Atendido" }, { value: "Pendiente", key: "Pendiente" }]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                     },
                                 },*/
                                {
                                    headerName: 'Teléfono', field: 'phone', hide: false, editable: true, flex: 1,

                                    /*renderHeader: (props) => {
                                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderEditCell: (props) => <SimpleEditField {...props} />
                                },
                                {
                                    headerName: 'Whatsapp', field: 'test', hide: false,
                                    renderCell: (params) => {
                                        return <Link target="_blank" rel="noopener" href={"https://wa.me/" + params.row.phone}><WhatsApp color="success" /></Link>
                                    },
                                },
                                {
                                    headerName: 'Mensaje', field: 'chatButton', hide: false,
                                    renderCell: (params) => {
                                        return (
                                            <>

                                                {

                                                    params.row.phone
                                                        ?

                                                        params.row?.Chat?.id ?
                                                            <Button onClick={() => { window.open('/multiagente/chat?chatID=' + params.row.Chat.id, '_blank').focus(); }}>Ir al chat</Button>
                                                            :
                                                            <Button onClick={() => { window.open('/multiagente/chat?chatID=' + params.row.id + '&isClient=1', '_blank').focus(); }}>Ir al chat</Button>
                                                        /*                                                        <NewMessageModal phone={params.row.phone} />*/
                                                        :
                                                        'Sin acciones'
                                                }

                                            </>
                                        )
                                    },
                                },
                                {
                                    headerName: 'Correo', field: 'email', hide: false, editable: true, flex: 1,

                                    /*renderHeader: (props) => {
                                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderEditCell: (props) => <SimpleEditField {...props} />
                                },
                                {
                                    headerName: 'Producto', field: 'product', hide: false, editable: true, flex: 1,
                                    /*renderHeader: (props) => {
                                        return <CustomFilter isSelect keys={["id", "name"]} list={products} title={props.colDef.headerName} field={props.field} filterFunc={filterData} />
                                    },*/
                                    renderCell: (params) => <Grid md={12} sx={{ height: '100%' }} direction="column"
                                        alignItems="center"
                                        justifyContent="center">

                                        <Chip label={params.row.Product?.name} />

                                    </Grid>
                                },

                                {
                                    headerName: 'Comentarios', field: 'comments', hide: false, flex: 1,
                                    renderCell: (params) => {

                                        return (
                                            <Grid container>
                                                <Grid item md={10}>
                                                    {params.row.Comments?.length > 0 ? params.row.Comments[0].comments : 'Sin comentarios'}
                                                </Grid>

                                            </Grid>
                                        )
                                    }

                                },





                            ]}
                            checkboxSelection
                            onDelete={(rowsSelected) => {

                                let rs = [...clients]
                                rowsSelected.map((r) => {
                                    clients.map((r2, index) => {
                                        if (r.id == r2.id) {
                                            rs.splice(index, 1);
                                        }
                                    })

                                })

                            }}
                            onsSelectionChange={(rowsSelected) => {


                                let selectedTemp = [];
                                rowsSelected.map((r) => {
                                    clients.map((r2, index) => {
                                        if (r.id == r2.id) {
                                            selectedTemp.push(r2);
                                        }
                                    })

                                })

                                setSelectedClients(selectedTemp);

                                if (selectedTemp.length == 0) {
                                    setSelectedClients([]);
                                    setSelectedAdvisers([])
                                    setShowAddAdvisers(false);
                                }
                            }}

                            components={{ Footer: CustomFooter }}
                        />
                    </Grid>
                    {showAddAdvisers && selectedClients.length > 0 &&
                        <Grid item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            align="center"
                            alignItems="center"
                            justifyContent="center"

                            sx={{ height: '100%' }}>
                            <TablaUsuarios

                                rows={advisers}
                                columns={[

                                    {
                                        headerName: 'Correo', field: 'email', hide: false, flex: 1,

                                    },
                                    {
                                        headerName: 'Nombre', field: 'firstName', hide: false, flex: 1,

                                    },
                                    {
                                        headerName: 'Estatus', field: 'status', hide: false, flex: 1,

                                    },


                                ]}
                                checkboxSelection
                                onDelete={(rowsSelected) => {


                                }}
                                onsSelectionChange={(newUsers) => {

                                    setSelectedAdvisers(newUsers);


                                }}


                            />
                            <NewMessageModal phone={'5547649443'} />
                        </Grid>}
                </Grid>
                {
                    selectedClients.length > 0 && !showAddAdvisers && <div style={{ textAlign: 'center', position: 'fixed', bottom: 16, width: '100%' }}>

                        {showAdviserButton && revisaPermisos(['Asignar clientes']) && <Fab variant="extended" onClick={() => setShowAddAdvisers(true)}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar asesor
                        </Fab>}
                        {revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setScheduleMsg(false); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar Mensaje
                        </Fab>}
                        {revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setScheduleMsg(true); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Programar Mensaje
                        </Fab>}
                        {revisaPermisos(['Enviar llamada masiva']) && <Fab variant="extended" onClick={() => { setScheduleCall(true); setOpenCallModal(true) }}>
                            <Navigation sx={{ mr: 1 }} />
                            Programar Llamada
                        </Fab>}
                        {revisaPermisos(['Enviar llamada masiva']) && <Fab variant="extended" onClick={() => { setScheduleCall(false); setOpenCallModal(true) }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar Llamada
                        </Fab>}
                        {revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setIsSequence(true); setScheduleMsg(false); setOpen(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar secuencia
                        </Fab>}
                        {revisaPermisos(['Enviar mensaje masivo']) && <Fab variant="extended" onClick={() => { setOpenEmailModal(true); }}>
                            <Navigation sx={{ mr: 1 }} />
                            Enviar correo
                        </Fab>}
                        {
                            revisaPermisos(['Mover clientes']) &&
                            <Fab variant="extended" onClick={() => { setScheduleCall(false); setOpenCallModal(false); setOpenMove(true); }}>
                                <Navigation sx={{ mr: 1 }} />
                                Mover
                            </Fab>
                        }

                    </div>
                }{
                    showAddAdvisers && selectedClients.length > 0 && <div style={{ textAlign: 'center', position: 'fixed', bottom: 16, width: '100%' }}>
                        <Fab color="success" variant="extended" onClick={() => setAdviserToClients()} disabled={selectedAdvisers.length == 0}>
                            <Navigation sx={{ mr: 1 }} />
                            Asignar
                        </Fab>
                        <Fab color="error" variant="extended" onClick={() => {

                            setSelectedAdvisers([])
                            setShowAddAdvisers(false);
                        }}>
                            <Navigation sx={{ mr: 1 }} />
                            Cancelar
                        </Fab>

                    </div>
                }
            </Grid >


            <MassiveMsgModal
                theme={theme}
                forwardingId={0}
                open={open}
                setOpen={setOpen}
                title={"Envio masivo"}
                whatsapp={false}
                contactsExternal={getPhones()}
                reloadMain={() => { setOpen(false); }}
                scheduled={scheduleMsg}
                isSequence={isSequence}
            />

            <CallModal
                title={"Llamada masiva"}
                open={openCallModal}
                setOpen={setOpenCallModal}
                theme={theme}
                templateList={templateList}
                onSave={(currentTemplate, name, phone, sendAt) => { checkSendType(currentTemplate, name, phone, sendAt) }}
                scheduled={scheduleCall}
            />

            <MoveModal
                title={"Mover datos a una nueva base"}
                currentDatabaseID={groupID}
                open={openMove}
                setOpen={setOpenMove}
                theme={theme}
                onSave={moveDataToDatabase}
            />

            <SendMassiveMailModal
                setOpen={setOpenEmailModal}
                open={openEmailModal}
                title={"Envio masivo de correos"}
                emailList={getMails()}
            />

            <ClientInfoModal
                products={products}
                status={status}
                chat={selectedClient}
                open={openInfo}
                setOpen={setOpenInfo}
                tags={tags}
                reload={() => setReloadClients(!reloadClients)}
            />

        </>
    )
}

export default DatabaseTable;