import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import QuoteModal from "./QuoteModal";

export default function NewQuote({ client, quote,company,products,variant="outlined", type="Cotizacion" }) {
    const [clientInfo, setClientInfo] = useState(client)
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setClientInfo(client);
    }, [client])

    return (
        <>
            <Button fullWidth variant={variant} onClick={() => setOpen(true)}>Crear {type.toLowerCase()}</Button>
            <QuoteModal
                client={clientInfo}
                open={open}
                setOpen={setOpen}
                externalQuote={quote}
                externalCompany={company}
                externalProducts={products}
                type={type}
            />
        </>

    )

}