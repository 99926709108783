import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid } from '@mui/material';
import WebhookMsgTable from '../WebhookMsgTable';
import Modal from '@mui/material/Modal';
import HisrotySequence from '../HisrotySequence';
import HistorySequence from '../HisrotySequence';

export default function WebhookMsgModal({ isEmail, title, open, setOpen, theme, messages, showResendButton, history, onResednAll, onResend, onEdit }) {

    /**
     * Carga los nuevos mensajes
     */
    useEffect(() => {

        setMessagesLocal(messages)
        setView(0)
    }, [messages]);

    /**
     * Variable para guardar los mensajes
     */
    const [messagesLocal, setMessagesLocal] = useState([]);

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    const [view, setView] = useState(0)
    return (
        <>
            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"


            >


                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >


                <DialogContent dividers>
                    <Grid
                        container

                        sx={{ height: '70vh', minWidth: '100%' }}
                        alignItems="center"
                        justify="center">

                        {

                            history?.length > 0 &&
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                align="center"
                            >
                                <Button onClick={() => setView(0)} variant={view == 0 ? 'contained' : 'outlined'} fullWidth>Historico de clientes</Button>
                            </Grid>
                        }
                        {

                            history?.length > 0 &&
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                align="center"
                            >
                                <Button onClick={() => setView(1)} variant={view == 1 ? 'contained' : 'outlined'} fullWidth>Historico de seccuencias</Button>
                            </Grid>
                        }

                        {
                            view == 0 &&
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                align="center"
                                sx={{ height: '90%' }}
                            >
                                <WebhookMsgTable
                                    showResendButton={showResendButton}
                                    massiveMsg={messages}
                                    onSave={() => { }}
                                    onResendAll={() => {
                                        onResednAll(messages)



                                    }}
                                    onEdit={onEdit}
                                    onResend={(phones) => { onResend(phones) }}
                                    isEmail={isEmail}
                                />

                            </Grid>
                        }
                        {
                            view == 1 &&
                            <Grid

                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                               
                                container
                                item
                                sx={{ height: '90%' }}
                            >
                                {
                                    history.map((history) => {
                                        return (
                                            <Grid item>
                                                <HistorySequence
                                                    key={history.id}
                                                    name={history.name}
                                                    creationDate={history.createdAt}
                                                    endDate={history.updatedAt}
                                                    status={history.status}

                                                />
                                            </Grid>
                                        )

                                    })
                                }

                            </Grid>
                        }

                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>

                </DialogActions>
            </Dialog>

        </>
    );
}
