import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import QuotesList from "./QuotesList";
import { useState } from "react";

export default function QuotesModal({ clientID, variant="outlined", type="Cotizaciones" }) {
    const [open, setOpen] = useState(false);
    function handleClose() {
        setOpen(false);
    }
    return (
        <>
            <Button fullWidth onClick={() => setOpen(true)} variant={variant}>Ver {type.toLowerCase()}</Button>
            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                maxWidth="lg"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {type}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <QuotesList
                                clientID={clientID}
                                type={type}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}