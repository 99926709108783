import React from "react";
// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

//paginas
//import Sesiones from "./pages/pages/Sesiones";

// Protected routes
import Accounts from "./pages/Accounts";
import Permissions from "./pages/Permissions";
import Users from "./pages/Users";

import Sesiones from "./pages/Sesiones";
import TwilioPhones from "./pages/TwilioPhones";


import Database from "./pages/Database";
import Groups from "./pages/Groups";


import CallHistory from "./pages/CallHistory";
import MsgHistory from "./pages/MsgHistory";

import Templates from "./pages/Templates";
import Tags from "./pages/Tags";
import Status from "./pages/Status";
import Speech from "./pages/Speech";
import UserModules from "./pages/UserModules";


import Files from "./pages/Files";
import ClientList from "./pages/ClientList";
import Products from "./pages/Products";
import Memberships from "./pages/Memberships";
import Organizations from "./pages/Organizations";
import Atention from "./pages/Atention";
import Trash from "./pages/Trash";
import Chatbot from "./pages/Chatbot";
import Estates from "./pages/Estates";
import Chat2 from "./pages/pages/Chat2";
import ContactList from "./pages/ContactList";
import GoogleContacts from "./pages/GoogleContacts";
import GoogleContactsList from "./pages/ContactsList";
import Sequences from "./pages/Sequences";
import GoogleInventory from "./pages/GoogleInventory";
import TemplateEditor from "./pages/TemplateEditor";
import EmailHistory from "./pages/EmailHistory";
import MassiveMessage from "./pages/MassiveMessage";
import ChatV2 from "./pages/ChatV2";
import Panel from "./pages/Panel";
import ChatbotTradicional from "./pages/ChatbotTradicional";
import ChatbotTemplates from "./pages/ChatbotTemplates";
import MassiveTagging from "./pages/MassiveTagging";
import PagePost from "./pages/PostPages";
import MassiveEmail from "./pages/MassiveEmail";
import FbChat from "./pages/FbChat";
import FacebookForms from "./pages/FacebookForms";
import CalendarDates from "./pages/CalendarDates";
import CalendarConfig from "./pages/CalendarConfig";
import ClientCalendar from "./pages/ClientCalendar";
import Invoices from "./pages/Invoices";
import ExternalQuoteView from "./pages/ExternalQuoteView";
import QuotesList from "./pages/QuotesList";
import Emails from "./pages/Emails";
import FBHistory from "./pages/FBHistory";
import InvoicesList from "./pages/InvoicesList";
import MassiveSequences from "./components/MassiveSequences/MensajeEnvio";
import MassiveSequence from "./pages/MassiveSequence";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "calendarios",
    children: [
      {
        path: ":userId",
        children: [
          {
            path: ":formId",
            element: <ClientCalendar />,
          },
        ],
      },
    ],
  },
  {
    path: "quotes",
    children: [
      {
        path: ":quoteID",
        element: <ExternalQuoteView />,
       
      },
    ],
  },
  {
    path: "Inicio",
    element: <DashboardLayout />,
    children: [
      {
        path: "Modulos",
        element: <UserModules />,
      },

    ],
  },
  {
    path: "almacenamiento",
    element: <DashboardLayout />,
    children: [
      {
        path: "archivos",
        element: <Files />,
      },

    ],
  },
  {
    path: "cotizaciones",
    element: <DashboardLayout />,
    children: [
      {
        path: "lista",
        element: <QuotesList />,
      },

    ],
  },
  {
    path: "facturas",
    element: <DashboardLayout />,
    children: [
      {
        path: "lista",
        element: <InvoicesList />,
      },

    ],
  },
  {
    path: "admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "membresias",
        element: <Memberships />,
      },
      {
        path: "organizaciones",
        element: <Organizations />,
      },

    ],
  },
  {
    path: "administracion",
    element: <DashboardLayout />,
    children: [
      {
        path: "usuarios",
        element: <Users />,
      },
      {
        path: "permisos",
        element: <Permissions />,
      },
      {
        path: "Modulos",
        element: <UserModules />,
      },
      {
        path: "Horarios",
        element: <Panel />,
      },
    ],
  },
  {
    path: "social",
    element: <DashboardLayout />,
    children: [

      {
        path: "cuentas",
        element: <Accounts />,
      },
      {
        path: "formularios",
        element: <FacebookForms />,
      },
      {
        path: "posts",
        element: <PagePost />,
      },
      {
        path: "whatsapp",
        element: <Sesiones />,
      },
      {
        path: "twilio",
        element: <TwilioPhones />,
      },
    ],
  },
  {
    path: "masivo",
    element: <DashboardLayout />,
    children: [

      {
        path: "envio",
        element: <MassiveMessage />
      },
      {
        path: "secuencia",
        element: <MassiveSequence />
      },
      {
        path: 'correo',
        element: <MassiveEmail />
      },
      {
        path: "etiquetado",
        element: <MassiveTagging />
      },

    ],
  },
  {
    path: "clientes",
    element: <DashboardLayout />,
    children: [
      {
        path: "nuevo-libro",
        element: <Database />,
      },
      {
        path: "ver-libros",
        element: <Groups />,
      },
      {
        path: "matriz",
        element: <ClientList />,
      },
      {
        path: "atencion",
        element: <Atention />,
      },
      {
        path: "papelera",
        element: <Trash />,
      },
    ],
  },
  {
    path: "multiagente",
    element: <DashboardLayout />,
    children: [
      {
        path: "chat",
        element: <ChatV2 />,
      },
      {
        path: "fb-chat",
        element: <FbChat />,
      },
      {
        path: "emails",
        element: <Emails />,
      },
      {
        path: "contactos",
        element: <ContactList />,
      },
    ],
  },
  {
    path: "chatbot",
    element: <DashboardLayout />,
    children: [
      {
        path: "activacion",
        element: <Chatbot />,
      },
      {
        path: "tradicional",
        element: <ChatbotTradicional />,
      },
      {
        path: "plantillas",
        element: <ChatbotTemplates />,
      },
    ],
  },
  {
    path: "inmobiliaria",
    element: <DashboardLayout />,
    children: [
      {
        path: "propiedades",
        element: <Estates />,
      },
    ],
  },
  {
    path: "bitacora",
    element: <DashboardLayout />,
    children: [
      {
        path: "llamadas",
        element: <CallHistory />,
      },
      {
        path: "mensajes",
        element: <MsgHistory />,
      },
      {
        path: "correos",
        element: <EmailHistory />,
      },
      {
        path: "fb-mensajes",
        element: <FBHistory />,
      },
    ],
  },
  {
    path: "personalizacion",
    element: <DashboardLayout />,
    children: [
      {
        path: "etiquetas",
        element: <Tags />,
      },
      {
        path: "estatus",
        element: <Status />,
      },
      {
        path: "productos",
        element: <Products />,
      },
      {
        path: "inventario",
        element: <GoogleInventory />,
      },
    ],
  },
  {
    path: "calendario",
    element: <DashboardLayout />,
    children: [
      {
        path: "citas",
        element: <CalendarDates />,
      },
      {
        path: 'configuracion',
        element: <CalendarConfig />,
      }
    ],
  },
  {
    path: "plantillas",
    element: <DashboardLayout />,
    children: [
      {
        path: "plantillas",
        element: <Templates />,
      },
      {
        path: "secuencias",
        element: <Sequences />,
      },
      {
        path: "speach",
        element: <Speech />,
      },
      {
        path: "email",
        element: <TemplateEditor />,
      },
      {
        path: "cotizacion",
        element: <Invoices />,
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "inicio",
        element: <UserModules />,
      },
    ],
  },
  {
    path: "contacts",
    element: <DashboardLayout />,
    children: [
      {
        path: "accounts",
        element: <GoogleContacts />,
      },
      {
        path: "list",
        element: <GoogleContactsList />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
