import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import api from '../../services/api';
import { ExpandMore, Facebook, WindowSharp } from '@mui/icons-material';
import { Box } from '@mui/system';
import MultiPageSelect from '../MultiPageSelect/Index';



export default function FormSyncModal({ title, open, setOpen, onSave, theme, pages, forms, setPageID, userId }) {

    /**
     * Consulta las bases de datos
     */
    useEffect(() => {

        async function getDatabases() {
            try {

                const { data } = await api.post("/api/getGroups", { filter: { id: userId } });

                if (data.status == "Success") {
                    setDatabases(data.books);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }

        async function getSheets() {
            try {

                const { data } = await api.post("/api/getGroups", { filter: { id: userId } });

                if (data.status == "Success") {
                    setDatabases(data.books);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }

        async function getWhatsapps() {
            try {

                const userInfo = await api.post("/api/getPermissions");

                const { data } = await api.post("api/getSessions", { wpSessions: userInfo.data.userInfo.wpsessions });


                if (data.status == "Success") {
                    setWhatsappList(data.sessions);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }

        async function getTemplates() {
            try {

                const { data } = await api.post("/api/getTemplate", { filter: { id: userId } });

                if (data.status == "Success") {
                    setTemplateList(data.messageTemplates);
                } else {

                }

            } catch (err) {
                console.log(err);
            }

        }

        async function getAccounts() {
            try {

                const { data } = await api.post('/api/getAccounts');
                setEmails(data.googleAccounts);
            } catch (err) {

            }
        }

        async function getSequences() {
            try {

                const { data } = await api.post('/api/sequence/get');
                setSequenceList(data.scheduleSequenceList);
            } catch (err) {

            }
        }

        if (userId) {
            getDatabases();
            getWhatsapps();
            getTemplates();
            getAccounts();
            getSequences()
        }


    }, [userId]);

    /**
     * Asigna la lista de los formularios
     */
    useEffect(() => {

        setFormList(forms);

    }, [forms]);





    /**
    * Asigna la lista de las paginas
    */
    useEffect(() => {

        setPageList(pages);
        setTestLead(null);
    }, [pages]);

    useEffect(() => {
        /*setExpanded('panel1');
        setCurrentFormID(null);
        setCurrentPageID(null);
        setCurrentDatabase(null);
        setCurrentBookPage(null);
        setDataCompleted(false);
        setWhatsappID(null);
        setTempalteID(null);
        setTestLead(null);*/
    }, [open])

    /**
     * Datos para elegir la base de datos
     */
    const [dataBases, setDatabases] = useState([]);
    const [bookPages, setBookPages] = useState([]);

    const [currentDatabase, setCurrentDatabase] = useState(null);

    const [currentBookPage, setCurrentBookPage] = useState(null);
    const [currentBookPages, setCurrentBookPages] = useState([]);
    const [dataCompleted, setDataCompleted] = useState(false);

    /**
     * Datos para el envio de mensaje
     */
    const [whatsappList, setWhatsappList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [sequenceList, setSequenceList] = useState([]);

    const [whatsappID, setWhatsappID] = useState(null);
    const [templateID, setTempalteID] = useState(null);
    const [emailId, setEmailId] = useState(null);
    const [selectedSequenceID, setSelectedSequenceID] = useState(null)

    const [emails, setEmails] = useState([])

    /**
     * Lista de las paginas
     */
    const [pageList, setPageList] = useState(pages);

    /**
     *lista de los formularios de la pagina
     */
    const [formList, setFormList] = useState([]);

    /**
     * Número para pruebas
     */
    const [currentPageID, setCurrentPageID] = useState(null);

    const [currentFormID, setCurrentFormID] = useState(null);

    const [testLead, setTestLead] = useState(null);

    /*const [currentStructure, setCurrentStructure] = useState({ "A": { "localColName": "firstName", "simpleName": "Nombre" }, "B": { "localColName": "lastName", "simpleName": "Apellidos" }, "C": { "localColName": "country", "simpleName": "País" }, "D": { "localColName": "city", "simpleName": "Ciudad" }, "E": { "localColName": "gender", "simpleName": "Gnero" }, "F": { "localColName": "level", "simpleName": "Nivel educativo" }, "G": { "localColName": "phone", "simpleName": "Telfono" } });*/

    const [currentStructure, setCurrentStructure] = useState({ "A": { "localColName": "firstName", "simpleName": "Nombre" }, "B": { "localColName": "lastName", "simpleName": "Apellidos" }, "C": { "localColName": "phone", "simpleName": "Telfono" }, "D": { "localColName": "email", "simpleName": "Correo" }, "E": { "localColName": "Tags", "simpleName": "Categoria" }, "F": { "localColName": "Product", "simpleName": "Producto" } });
    const [currentStructureArray, setCurrentStructureArray] = useState([]);

    const [loading, setLoading] = useState(false);

    /**
    * Notifiaciones
    */
    const { enqueueSnackbar } = useSnackbar();

    const [expanded, setExpanded] = useState('panel1');

    const [checked, setChecked] = useState(true);

    const handleChangeBox = (event) => {
        setChecked(event.target.checked);
    };

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };


    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const array = Object.entries(currentStructure).map(([key, value]) => ({ key, ...value }));

        // Ordenamos el array por el campo simpleName
        array.sort((a, b) => a.simpleName.localeCompare(b.simpleName));

        setCurrentStructureArray(array)

    }, [])
    useEffect(() => {

        async function getPages() {
            try {

                const { data } = await api.post('/api/getPages', { bookID: currentDatabase.id });
                setBookPages(data.pages);
            } catch (err) {

            }
        }

        if (currentDatabase) {
            getPages();
        }

    }, [currentDatabase])

    /**
     * Envia la prueba hacia el formulario
     */
    const callTestLead = async (formID) => {

        setLoading(true);
        try {
            const { data } = await api.post('/api/testLead', { pageID: currentPageID.id, formID: formID });

            if (data.status == "Success") {
                setTestLead(data.data.field_data)
                enqueueSnackbar(`Prueba enviada exitosamente`, {
                    variant: 'success',
                });

                setExpanded('panel3');
            } else {

                if (data?.errorCode == 'NDE100') {
                    enqueueSnackbar(`No se pudo recuperar la estructura del formulario`, {
                        variant: 'warn',
                    });
                } else {
                    console.log(data);
                    enqueueSnackbar(`Ocurrio un error al sincronizar el formulario ${data?.msg[0]}`, {
                        variant: 'error',
                    });
                }

            }

        } catch (err) {
            console.log(err);
            enqueueSnackbar(`Ocurrio un error al sincronizar el formulario ${err.message}`, {
                variant: 'error',
            });
        }
        setLoading(false);
    }

    /**
     * Construye la nueva estructura de datos
     * @param {*} newField 
     * @param {*} oldField 
     */
    const handleStructureChange = (newField, oldField) => {
        let tempStructure = { ...currentStructure }

        let deleteOLD = true
        if (tempStructure[newField] != null) {
            tempStructure[oldField] = currentStructure[newField]
            deleteOLD = false;
        }
        tempStructure[newField] = { ...currentStructure[oldField] }

        if (deleteOLD) {
            delete tempStructure[oldField];
        }

        // Convertimos el JSON a un array de objetos incluyendo la clave original
        const array = Object.entries(tempStructure).map(([key, value]) => ({ key, ...value }));

        // Ordenamos el array por el campo simpleName
        array.sort((a, b) => a.simpleName.localeCompare(b.simpleName));

        setCurrentStructureArray(array)

        setCurrentStructure({ ...tempStructure });
    }

    /**
     * Revisa si se va a configurar el mensaje
     */
    const checkSendType = () => {

        if (!whatsappID || !templateID) {

            if (!window.confirm("No se a configurado un mensaje de respuesta automático ¿Desea continuar?")) {
                return;
            }
        }

        onSave(currentPageID.id, currentFormID.id, currentStructure, checked, currentBookPages, whatsappID, templateID, emailId)
        setExpanded('panel1');
        setCurrentFormID(null);
        setCurrentPageID(null);
        setCurrentDatabase(null);
        setCurrentBookPage(null);
        setDataCompleted(false);
        setWhatsappID(null);
        setTempalteID(null);
        setTestLead(null);
        setSelectedSequenceID(null)
    }
    return (
        <>

            <Dialog
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle >

                <DialogContent dividers>
                    <Grid container spacing={2}>


                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{
                                        //backgroundColor: currentPageID ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            currentPageID ?
                                                <Alert severity="success">
                                                    Paso 1
                                                </Alert>
                                                : "Paso 1"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Página</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Autocomplete
                                        options={pageList}
                                        getOptionLabel={(option) => option.name}
                                        id="pagest"

                                        clearOnEscape
                                        onChange={(event, newValue) => {

                                            if (newValue) {
                                                setCurrentPageID(newValue);
                                                setPageID(newValue.id);
                                                setExpanded('panel2');
                                            }

                                        }

                                        }
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <Facebook color="secondary" /> {option.name} ({option.category})
                                            </Box>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} label="Selecciona un página" variant="standard" />

                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2' && currentPageID != null} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    sx={{
                                        //backgroundColor: testLead ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            testLead ?
                                                <Alert severity="success">
                                                    Paso 2
                                                </Alert>
                                                : "Paso 2"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Formulario
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={checked}
                                            onChange={handleChangeBox}
                                            inputProps={{ 'aria-label': 'controlled' }} />} label="Sincronizar leads anteriores" />

                                    </FormGroup>
                                    <Autocomplete
                                        options={formList}
                                        getOptionLabel={(option) => option.name}
                                        id="forms"

                                        clearOnEscape
                                        onChange={(event, newValue) => {

                                            if (newValue) {
                                                setCurrentFormID(newValue);
                                                if (newValue.columnsStructure) {
                                                    // const json = JSON.parse(newValue.columnsStructure);

                                                    //setCurrentStructure(json);
                                                    setCurrentStructure(currentStructure);
                                                } else {



                                                    setCurrentStructure(currentStructure);

                                                }

                                                callTestLead(newValue.id);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Selecciona un formulario" variant="standard" />
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel3' && testLead != null} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    sx={{
                                        //backgroundColor: testLead ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            testLead ?
                                                <Alert severity="success">
                                                    Paso 3
                                                </Alert>
                                                : "Paso 3"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Prueba de Lead
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <pre>
                                        {JSON.stringify(testLead, null, 2)}
                                    </pre>
                                    <br />
                                    <Button fullWidth onClick={() => { setExpanded('panel4'); }} variant='outlined'>Continuar</Button>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel4' && testLead != null} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    sx={{
                                        //backgroundColor: currentDatabase ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            currentBookPages.length > 0 ?
                                                <Alert severity="success">
                                                    Paso 4
                                                </Alert>
                                                : "Paso 4"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Asigna la hoja para los datos
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MultiPageSelect onComplete={(pagesAndBooks) => {
                                        setExpanded('panel5');
                                        setCurrentBookPages(pagesAndBooks)

                                    }} />

                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                    sx={{
                                        //backgroundColor: dataCompleted ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            dataCompleted ?
                                                <Alert severity="success">
                                                    Paso 5
                                                </Alert>
                                                : "Paso 5"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Configurar los datos
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container spacing={2}>
                                        {currentStructure &&
                                            currentStructureArray?.map((structure) => {

                                                return (
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>

                                                        <TextField
                                                            fullWidth
                                                            select
                                                            value={structure.key}
                                                            label={currentStructure[structure.key].simpleName}
                                                            name="type"
                                                            variant="outlined"
                                                            onChange={(event) => { handleStructureChange(event.target.value, structure.key) }}
                                                        >
                                                            {testLead?.map((option) => (
                                                                <MenuItem key={option.name} value={option.name}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>

                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Button fullWidth onClick={() => { setDataCompleted(true); setExpanded('panel6'); }} variant='outlined'>Continuar</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6' && dataCompleted} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    sx={{
                                        //backgroundColor: whatsappID && templateID ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            whatsappID && templateID ?
                                                <Alert severity="success">
                                                    Paso 6 (opcional)
                                                </Alert>
                                                : "Paso 6 (opcional)"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Guardar leads como contactos de gmail
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            emails.length > 0 ?

                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Autocomplete
                                                        options={emails}
                                                        getOptionLabel={(option) => option.name}
                                                        id="forms6"
                                                        clearOnEscape
                                                        onChange={(event, newValue) => {

                                                            if (newValue) {
                                                                setEmailId(newValue.id);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona un correo" variant="standard" />
                                                        )}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    {whatsappList.length == 0 &&
                                                        <Alert severity="warning">
                                                            No se han configurado correos, para configurar uno contacte al administrador
                                                        </Alert>}
                                                </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Button fullWidth onClick={() => { setExpanded('panel7'); }} variant='outlined'>Continuar</Button>
                                        </Grid>
                                    </Grid>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7' && dataCompleted} onChange={handleChange('panel7')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    sx={{
                                        //backgroundColor: whatsappID && templateID ? "#edf7ed" : "secondary"
                                    }}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            whatsappID && templateID ?
                                                <Alert severity="success">
                                                    Paso 7 (opcional)
                                                </Alert>
                                                : "Paso 7 (opcional)"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Asignar mensaje autmático de respuesta
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            whatsappList.length > 0 && templateList.length > 0 ?

                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Autocomplete
                                                        options={whatsappList}
                                                        getOptionLabel={(option) => option.name}
                                                        id="forms7"
                                                        clearOnEscape
                                                        onChange={(event, newValue) => {

                                                            if (newValue) {
                                                                setWhatsappID(newValue.id);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona un whatsapp" variant="standard" />
                                                        )}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    {whatsappList.length == 0 &&
                                                        <Alert severity="warning">
                                                            No se han configurado whatsapps, para configurar uno vaya al menu de "Sesiones" y agregué una nueva
                                                        </Alert>}
                                                </Grid>
                                        }
                                        {
                                            templateList.length > 0 && whatsappList.length > 0 ?
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Autocomplete
                                                        options={templateList}
                                                        getOptionLabel={(option) => option.name}
                                                        id="forms7"
                                                        clearOnEscape
                                                        onChange={(event, newValue) => {

                                                            if (newValue) {
                                                                setTempalteID(newValue.id);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona una plantilla de mensaje" variant="standard" />
                                                        )}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    {templateList.length != 0 &&
                                                        <Alert severity="warning">
                                                            No se han configurado plantilla, para agregar una nueva vaya al menu de "Plantillas"
                                                        </Alert>}
                                                </Grid>
                                        }
                                    </Grid>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8' && dataCompleted} onChange={handleChange('panel8')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {
                                            selectedSequenceID ?
                                                <Alert severity="success">
                                                    Paso 8 (opcional)
                                                </Alert>
                                                : "Paso 8 (opcional)"
                                        }
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        Asignar secuencia de respuesta
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            sequenceList?.length > 0 ?

                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Autocomplete
                                                        options={sequenceList}
                                                        getOptionLabel={(option) => option.name}
                                                        id="forms8"
                                                        clearOnEscape
                                                        onChange={(event, newValue) => {

                                                            if (newValue) {
                                                                setSelectedSequenceID(newValue.id);
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Selecciona una secuencia" variant="standard" />
                                                        )}
                                                    />
                                                </Grid>
                                                :
                                                <Grid item xs={12} sm={12} md={12} lg={12}>

                                                    <Alert severity="warning">
                                                        No se han configurado secuencias para esta cuenta
                                                    </Alert>
                                                </Grid>
                                        }

                                    </Grid>

                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>


                    <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button disabled={!dataCompleted} theme={theme} variant="contained" color="secondary" onClick={checkSendType}>
                        Guardar
                    </Button>


                </DialogActions>
            </Dialog>
        </>
    );
}
