import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import MensajeEnvio from "../../components/MensajeEnvio/MensajeEnvio";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const MassiveSequence = () => {

    const [reload, setReload] = useState(false);
    const [openContacts, setOpenContacts] = useState(false);

    return (
        <>
            <Helmet title="Envio Masivo" />

            <Typography variant="h3" gutterBottom display="inline">
                Envio masivo
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link>
                    secuencias
                </Link>


                <Button variant='outlined' onClick={() => { setOpenContacts(true) }} >Agregar contactos</Button>


            </Breadcrumbs>
            <Grid container sx={{ height: '85vh', maxWidth: '100vw' }} alignItems="center"
                justify="center">
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ height: '90%' }}
                >
                    <MensajeEnvio
                        theme={null}
                        forwardingId={0}
                        whatsapp={null}
                        reloadMain={() => setReload(!reload)}
                        scheduled={false}
                        isSequence={true}
                        openContacts={openContacts}
                        setOpenContacts={setOpenContacts}
                        
                    />

                </Grid>
            </Grid>

        </>

    )
}

export default MassiveSequence;