

import TablaUsuarios from "../TablaUsuarios/TablaUsuarios";
import CustomFilter from "../CustomFilter"
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Grid, IconButton, TextField } from "@mui/material";
import Detail from "../DetailTable";
import DetailPanel from "../DetailPanel";
import { ConstructionOutlined, Done } from "@mui/icons-material";
import api from "../../services/api";
import { useSnackbar } from "notistack";
import { SocketContext } from "../../services/socket";
import { formatDate } from "../../utils/generalFunctions";

/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const MassiveTable = ({ isFB,massiveMsg, whatsapps, onDelete, onEdit, showEdit, showResent, showDelete, setCurrent, forwardingId, kind, onResend, onResendAll, showResendButton, showResendAllButton, reload, isEmail }) => {
    /**
     * Cada que cambien  los datos filtra de nuevo
     */
    useEffect(() => {
        setFiltered(massiveMsg);
    }, [massiveMsg])
    useEffect(() => {
        setLocalWhatsapps(whatsapps);
    }, [whatsapps])

    useEffect(() => {

    }, [])
    const [localWhatsapps, setLocalWhatsapps] = useState([]);
    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] = useState('');

    /**
    * Guarda los datos filtrados
    */
    const [filtered, setFiltered] = useState(massiveMsg);

    /**
    * Guarda los datos de lso filtros
    */
    const [latsFilters, setLatsFilters] = useState({});

    const [localFilters, setLocalFilters] = useState({});

    /**
      * Barra de notificaciones
      */
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Socket para el envio masivo
     */
    const socket = useContext(SocketContext);

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {

        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }

        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = massiveMsg.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

                    } else {

                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }
                }


            }
            return isInFilter;
        });

        setFiltered(finalResults);
    };

    /**
    * Vista para la edición de un campo simple
    * @param {*} props 
    * @returns 
    */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        const [newValue, setNewValue] = useState(value)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            setNewValue(target.value)
        }

        /**
         * Cuando presiona el botón se guarda el nuevo valor
         */
        const handleCommit = async (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();


            /**
             * Objeto para enviar la petición de actualización
             */
            const updateObj = {
                id: props.row.id,
                name: newValue,
            }

            await onEdit(updateObj);
        };



        return (
            <Grid container sx={{ margin: 0, width: '100%' }} >
                <Grid item xs={8} sm={8} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        defaultValue={value}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={4} sm={4} md={2} lg={2} >
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            minHeight: '100%',
                            margin: 0,
                            width: '100%',
                        }} >
                        <Grid item>
                            <IconButton sx={{ height: '100%', width: '100%' }} color="success" aria-label="menu" onClick={handleCommit}>
                                <Done color="success" />
                            </IconButton>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        )

    }

    /**
     * Actualiza el estatus deteniendo un envio
     * @param {*} id 
     * @param {*} status 
     */
    const updateStatus = async (id, status) => {
        try {
            const { data } = await api.post('/api/massivemessage/status', { id: id, status: status });

            if (data.status == "Success") {
                enqueueSnackbar("Se esta deteniendo el envio", {
                    variant: 'success',
                });
                reload();
            } else {
                enqueueSnackbar("Ocurrió un error al cancelar/pausar el envio", {
                    variant: 'error',
                });
            }

        } catch (err) {
            enqueueSnackbar("Ocurrió un error al cancelar/pausar el envio", {
                variant: 'error',
            });
        }
    }


    const continueSending = (massiveMessageObj) => {

        try {
            const socketObj = {
                id: massiveMessageObj.id,
                contacts: [],
                wpsession: massiveMessageObj.wpId,
                delay: massiveMessageObj.delay,
            }

            socket.emit("sendMassiveMessage", socketObj);

            enqueueSnackbar("Se retomó el envio", {
                variant: 'success',
            });
            reload();
        } catch (err) {
            enqueueSnackbar("Ocurrió un error al reanudar el envio", {
                variant: 'error',
            });
        }

    }

    const getWhatsappField = (sessionID, name) => {
        if(isFB){
            return name;
        }
        if (isEmail) {
            return sessionID;
        }
        
        const whatsapp = localWhatsapps?.find((whatsapp) => whatsapp.sessionAuth == sessionID)

        if (!whatsapp) {
            return <Alert severity="error">Whatsapp no encontrado</Alert>
        }

        if (whatsapp.status != 'Conectado') {
            return <Alert severity="warning">{whatsapp.name}</Alert>
        }

        return <Alert severity="success" >{whatsapp.name}</Alert>
    }

    const filterData = async (field, value) => {
        let filters = { ...localFilters, [field]: value };
        if (!value || value?.length == 0) {
            delete filters[field];
        }
        setLocalFilters(filters)

        try {
            const { data } = await api.post("/api/massive/listV2", { kind: 'Whatsapp', filters });
            setFiltered(data.messages)


        } catch (err) {
            alert("Ocurrio un error al filtrar la información");
        }

    }

    return (

        <Detail
            checkboxSelection={true}
            detail={({ row }) => <DetailPanel showResendButton={showResendButton} row={row} forwardingId={forwardingId} kind={kind ? kind : ""} onResend={onResend} onResendAll={onResendAll} />}
            height={400}
            rows={filtered}
            showDelete={showDelete}
            columns={[
                {
                    headerName: 'Grupo', field: 'name', flex: 1, hide: false, editable: true,
                    renderEditCell: (props) => <SimpleEditField {...props} />,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                    },
                },

                {
                    headerName: isFB? 'Formulario/Cuenta': isEmail ? 'Correo' : 'Sistema', field: 'wpId', flex: 1, hide: false,
                    renderCell: (props) => getWhatsappField(props.row.wpId, props.row.name),
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <>{isEmail ?
                            <CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />
                            :
                            <CustomFilter isSelect keys={["sessionAuth","name"]} list={whatsapps} title={props.colDef.headerName} field={props.field} filterFunc={filterData} width={props?.colDef?.minWidth} />
                        }</>
                    },
                },
                {
                    headerName: 'Espera', field: 'delay', flex: 1, hide: false,

                    renderCell: (params) => {

                        return (
                            <>
                                {params.row.delay / 1000}s
                            </>

                        )
                    }
                },
                {
                    headerName: 'Conteo', field: 'totalMessages', flex: 1, hide: false,

                    renderCell: (params) => {

                        return (
                            <>
                                {params.row.totalMessagesSend > 0 ? params.row.totalMessagesSend : params.row.totalMessagesSend} / {params.row.totalMessages ? params.row.totalMessages : '?'}
                            </>

                        )
                    }
                },
                {
                    headerName: 'Acciones', field: 'actions', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderCell: (params) => {
                        /** En este caso se puede cancelar */
                        if (params.row.status == 'En espera' || params.row.status == 'Programado') {
                            return <Button color="error" onClick={() => updateStatus(params.row.id, "Cancelado")}>Cancelar</Button>
                        }

                        /** En este caso se puede detener */
                        if (params.row.status == 'Enviando Mensajes') {
                            return <Button onClick={() => { updateStatus(params.row.id, "Detenido") }}>Detener</Button>
                        }

                        /** En este caso se puede retomar */
                        if (params.row.status == 'Detenido') {
                            return <Button color="success" onClick={() => continueSending(params.row)}>Reanudar</Button>
                        }


                        return <Button disabled >Sin acciones</Button>

                    }
                },
                {
                    headerName: 'Estatus', field: 'status', flex: 1, hide: false,
                    /** Filtro personalizado */
                    renderHeader: (props) => {
                        return <CustomFilter isSelect isArray list={["Detenido","Enviando Mensajes", "En espera", "Error", "Terminado"]} title={props.colDef.headerName} field={props.field} filterFunc={filterData} width={props?.colDef?.minWidth} />
                    },
                },
                {
                    headerName: 'Fecha de creación', field: 'createdAt', flex: 1, hide: false,
                    renderCell:(params)=>formatDate(params.row.createdAt)
                },
                {
                    headerName: 'Fecha de actualización', field: 'updatedAt', flex: 1, hide: false,
                    renderCell:(params)=>formatDate(params.row.updatedAt)
                },
                {
                    headerName: 'Ver bitacora', field: 'bitacora', flex: 1, hide: false,
                    renderCell: (params) => {

                        return (
                            <Button onClick={() => setCurrent(params.row.id)}>Ver bitacora</Button>

                        )
                    }
                },
                {
                    headerName: 'Reenvio', field: 'reenvio', flex: 1, hide: false,
                    renderCell: (params) => {

                        return (
                            <>
                                {
                                    showResendAllButton ? <Button onClick={() => showResent(params.row.id)}>Reenviar</Button>
                                        : "Sin acciones"
                                }
                            </>


                        )
                    }
                },

            ]}

            onDelete={onDelete}
            showResend={showResendButton}
        />

    )
}

export default MassiveTable; 