import * as React from 'react';

import BotonMas from '../../components/BotonMas/BotonMas';
import TablaUsuarios from '../../components/TablaUsuarios/TablaUsuarios';
import { useState } from 'react';
import api from '../../services/api';
import { SocketContext } from '../../services/socket';
import { Breadcrumbs, Button, Chip, Divider, Fab, Grid, Typography, Link } from '@mui/material';
import { useSnackbar } from "notistack";
import { Done, Error, Facebook, Reply, Sync } from '@mui/icons-material';
import { NavLink } from "react-router-dom";

import { FacebookProvider, LoginButton } from 'react-facebook';
import './index.css';
import Loading from '../../components/Loading';
import FormWebhookModal from '../../components/FormWebhookModal';
import { Helmet } from 'react-helmet-async';
import FormSyncModal from '../../components/FormSyncModal';

/**
 * Contenedor para gestionar las sesiones de FB y TK
 * @returns 
 */
export default function Accounts() {



  /**
   * Variables para activar el modal
   */

  const [open, setOpen] = useState(false);

  /**
   *  Variables para activar el modal del QR
   */
  const [estadoModal, setEstadoModal] = useState(false);


  /**
  * Variable que recibirá el objeto de sesiones
  * incluye su lista de mensajes
  */
  const [sessions, _setSessions] = useState([]);

  /**
   * Variables para activar el modal de sincronización
   */
  const [openSyncForm, setOpenSyncForm] = useState(false);
  /**
   * 
   */
  const sessionsRef = React.useRef(sessions);

  const setSessions = data => {
    sessionsRef.current = data;
    _setSessions(data);
  }

  /**
   * Variable que contiene la lista de permisos del usuario
   */
  const [permissionsUser, setPermissionsUser] = useState([]);

  /**
  * Variable que contiene la lista sistemas sobre los que tiene permiso
  */
  const [sessionsUser, setSessionsUser] = useState([]);
  /**
   * Socket para lo refrebte al chat
   */
  const socket = React.useContext(SocketContext);

  /**
  * Variable para recargar
  */
  const [reload, setReload] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  /**
   * Sesion actual
   */
  const [currentSession, setCurrentSession] = useState(null);

  /**
   * Cambia de vista entre paginas y cuentas
   */
  const [showPages, setShowPages] = useState(false);

  /**
   * Cambia de vista entre paginas y cuentas
   */
  const [showForms, setShowForms] = useState(false);

  /**
   * Paginas seleccioandas
   */
  const [currentPages, setCurrentPages] = useState([]);

  /**
  * Paginas seleccioandas
  */
  const [currentForms, setCurrentForms] = useState([]);

  /**
   * El id de la cuenta seleccionada
   */
  const [currentAccountID, setCurrentAccountID] = useState(null);

  /**
   * El id de la pagina
   */
  const [currentPageID, setCurrentPageID] = useState(null);

  /**
   * El id del formulario
   */
  const [currentFormID, setCurrentFormID] = useState(null);


  /**
   * El id de la cuenta seleccionada
   */
  const [currentUserID, setCurrentUserID] = useState(null);

  /**
   * 
   */
  const [openWebhookModal, setOpenWebhookModal] = useState(false);

  /**
 * 
 */
  const [currentWebhookUrl, setCurrentWebhookUrl] = useState('');

  /**
  * Notifiaciones
  */
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {

    setOpen(false);
    setEstadoModal(false);
    setIsLoading(true);

    async function getAccounts() {

      try {

        const userInfo = await api.post("/api/getPermissions");


        setPermissionsUser(userInfo.data.userInfo.permissions)
        setSessionsUser(userInfo.data.userInfo.wpsessions);
        setCurrentUserID(userInfo.data.userInfo.id);
        const { data } = await api.post("/api/accounts", { userID: userInfo.data.userInfo.id });

        setSessions(data.data);

      } catch (err) {
        console.log(err)
      }

      setIsLoading(false);
    }

    getAccounts();

  }, [reload]);

  /**
   * Consulta las páginas cada que cambia la cuenta seleccionada
   */
  React.useEffect(() => {

    async function getPages() {

      try {

        const { data } = await api.post("/api/userpages", { accountID: currentAccountID });

        setCurrentPages(data.data);

      } catch (err) {
        console.log(err)
      }
    }

    if (currentAccountID) {
      getPages();
    }


  }, [currentAccountID, reload]);

  /**
   * Consulta los formularios de una página
   */
  React.useEffect(() => {

    async function getForms() {

      try {

        const { data } = await api.post("/api/pageforms", { pageID: currentPageID });

        setCurrentForms(data.data);

      } catch (err) {
        console.log(err)
      }
    }

    if (currentPageID) {
      getForms();
    }


  }, [currentPageID, reload]);


  /**
  * Compara la lista de permisos con los permisos asignados
  */
  const revisaPermisos = (allow, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < permissionsUser.length; j++) {
        if (allow[i] == permissionsUser[j]) {
          count++;
        }
      }
    }

    return count >= minMatch;
  }

  React.useEffect(() => {

    if (socket && sessions.length) {

      if (sessions.length > 0) {


        sessions.map((session, key) => {

          socket.on('updated' + session.id, (data) => {
            setReload(!reload);
          })
        })
      }

    }
  }, [socket, sessions, reload])

  /**
   * Columnas de la tabla
   */
  const columns = [
    // { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Nombre de la cuenta',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Tipo de cuenta',
      flex: 1,
      renderCell: (params) => {

        if (params.row.type == 'FB') {
          return (
            <Facebook />
          )
        }

        if (params.row.type == 'TK') {
          return (
            <Facebook />
          )
        }

      }
    },
    {
      field: 'status',
      headerName: 'Estatus',
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            onClick={() => { }}
            onDelete={() => { }}
            deleteIcon={params.row.status == 'Sincronizada' ? <Done /> : <Error />}
            color={params.row.status == 'Sincronizada' ? 'success' : 'error'}
          />
        )
      }
    },
    {
      field: 'pagesCount',
      headerName: 'Páginas sincronizadas',
      flex: 1,
    },
    {
      field: 'test',
      headerName: 'Formularios',
      flex: 1,
      renderCell: (params) => {

        return (
          <Button onClick={() => {
            setCurrentAccountID(params.row.id);
            setOpenSyncForm(true);
          }}>Sincronizar formulario</Button>
        )
      }
    },
    {
      field: 'test2',
      headerName: 'Acciones',
      flex: 1,
      renderCell: (params) => {

        return (
          <Button onClick={() => {
            setShowPages(true);
            setCurrentAccountID(params.row.id);
          }}>Adminsitrar páginas</Button>
        )
      }
    },

  ];

  /**
   * Columnas de la tabla de paginas
   */
  const formColumns = [
    // { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Nombre de la cuenta',
      flex: 1,
    },

    {
      field: 'leadCount',
      headerName: 'Clientes potenciales',
      flex: 1,
    },
    {
      field: 'webhookUrl',
      headerName: 'Webhook',
      flex: 1,
      renderCell: (params) => {
        return (

          <Button variant='contained' onClick={() => configureWebhook(params.row.id, params.row.webhookUrl)}>{params.row.webhookUrl ? 'Configurar' : 'Editar'}</Button>

        )
      }
    },
    {
      field: 'status',
      headerName: 'Estatus',
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            onClick={() => { }}
            onDelete={() => { }}
            deleteIcon={params.row.status == 'ACTIVE' ? <Done /> : <Error />}
            color={params.row.status == 'ACTIVE' ? 'success' : 'error'}
          />
        )
      }
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de sincronización',
      flex: 1,
    },

  ];

  /**
  * Columnas de la tabla de paginas
  */
  const pageColumns = [
    // { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Nombre de la cuenta',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      flex: 1,

    },
    {
      field: 'formCount',
      headerName: 'Formularios sincronizados',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'Estatus',
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            onClick={() => { }}
            onDelete={() => { }}
            deleteIcon={params.row.status == 'Sincronizada' ? <Done /> : <Error />}
            color={params.row.status == 'Sincronizada' ? 'success' : 'error'}
          />
        )
      }
    },

    {
      field: 'test2',
      headerName: 'Acciones',
      flex: 1,
      renderCell: (params) => {

        return (
          <Button onClick={() => {
            setShowPages(false);
            setShowForms(true);
            setCurrentPageID(params.row.id);
          }}>Adminsitrar FORMULARIOS</Button>
        )
      }
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de sincronización',
      flex: 1,
    },

  ];
  /**
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deleteSession = async (dataSessions) => {

    if (dataSessions.length > 1) {
      if (!window.confirm(`¿Desea dejar de sincronizar las cuentas seleccionadas? Esto hará que ya no se muestren de forma automática los clientes que respondan sus formularios`)) {
        return;
      }
    } else {
      if (!window.confirm(`¿Desea dejar de sincronizar la cuenta ${dataSessions[0].name}? Esto hará que ya no se muestren de forma automática los clientes que respondan sus formularios`)) {
        return;
      }
    }
    setIsLoading(true);
    await Promise.all(dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/accounts", { id: ss.id });

        if (data.status == "Success") {

          enqueueSnackbar('Cuenta eliminada', {
            variant: 'success',
          });


        } else {

          enqueueSnackbar('Ocurrió un error al eliminar la cuenta', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar la cuenta', {
          variant: 'error',
        });
      }

      return true;
    }));

    setReload(!reload);


  }

  /**
   * Elimina una sesion por el id
   * @param {*} id 
   */
  const deletePage = async (dataSessions) => {

    if (dataSessions.length > 1) {
      if (!window.confirm(`¿Desea dejar de sincronizar las páginas seleccionadas? Esto hará que ya no se muestren de forma automática los clientes que respondan sus formularios`)) {
        return;
      }
    } else {
      if (!window.confirm(`¿Desea dejar de sincronizar la página ${dataSessions[0].name}? Esto hará que ya no se muestren de forma automática los clientes que respondan sus formularios`)) {
        return;
      }
    }
    setIsLoading(true);

    await Promise.all(dataSessions.map(async (ss, key) => {
      try {

        const { data } = await api.put("/api/userpages", { id: ss.id });

        if (data.status == "Success") {

          enqueueSnackbar('Cuenta eliminada', {
            variant: 'success',
          });


        } else {

          enqueueSnackbar('Ocurrió un error al eliminar la cuenta', {
            variant: 'error',
          });
        }

      } catch (err) {
        console.log(err)
        enqueueSnackbar('Ocurrió un error al eliminar la cuenta', {
          variant: 'error',
        });
      }

      return true;
    }));

    setReload(!reload);


  }
  /**
   * Abre la ventana de facebook y sincroniza los datos con la página
   * @param {*} response 
   */
  async function handleSuccess(response) {

    try {
      setIsLoading(true);
      const { data } = await api.post('/api/addFBLeads', { userID: currentUserID, userAuthToken: response.authResponse.accessToken })
    } catch (err) {
      enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
        variant: 'error',
      });
      console.log(err)
    }
    setReload(!reload);
  }

  function handleError(error) {
    enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
      variant: 'error',
    });
    console.log(error);
  }

  async function testLead() {
    try {
      const { data } = await api.post('/api/addNewLead/');
      console.log(data)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Abre la ventana de configuración para el webhook
   * @param {} webhookUrl 
   */
  const configureWebhook = async (id, webhookUrl) => {
    setCurrentFormID(id);

    if (webhookUrl) {
      setCurrentWebhookUrl(webhookUrl);
    }

    setOpenWebhookModal(true);

  }

  /**
   * Actualiza la url del webhook 
   * @param {*} webhookUrl 
   */
  const updateWebhookForForm = async (webhookUrl) => {
    try {
      setIsLoading(true);
      const { data } = await api.post('/api/updatePageWebhook', { id: currentFormID, url: webhookUrl })
    } catch (err) {
      enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
        variant: 'error',
      });
      console.log(err)
    }
    setReload(!reload);
  }

  /**
 * 
 * @param {*} pageID 
 * @param {*} formID 
 * @param {*} currentStructure 
 */
  const updatePageStructure = async (pageID, formID, currentStructure, syncOldLeads, groupID, wpID, templateID) => {
    try {
      setIsLoading(true);
      setOpenSyncForm(false);
      //id,pageID,userID,newStructure,syncOldLeads
      const { data } = await api.post('/api/updateFormStructure', { userID: currentUserID, id: formID, pageID: pageID, newStructure: currentStructure, syncOldLeads: syncOldLeads, groupID: groupID, wpID: wpID, templateID: templateID })
    } catch (err) {
      enqueueSnackbar('Ocurrió un error al sincronizar la cuenta', {
        variant: 'error',
      });
      console.log(err)
    }
    setReload(!reload);
  }

  return (
    <>
      <Helmet title="Cuentas de facebook" />

      <Typography variant="h2" gutterBottom display="inline">
        Gestión de cuentas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Redes sociales
        </Link>
        <Typography>Cuentas</Typography>
        {
          (!showPages && !showForms) && revisaPermisos(['Sincronizar cuenta social']) &&


          <LoginButton
            //scope="pages_show_list leads_retrieval pages_read_engagement pages_manage_ads pages_manage_metadata"
            scope="pages_show_list pages_messaging leads_retrieval pages_read_engagement pages_manage_metadata pages_read_user_content pages_manage_ads pages_manage_posts pages_manage_engagement pages_messaging pages_messaging_subscriptions instagram_manage_messages instagram_basic business_management pages_show_list"
            onError={handleError}
            onSuccess={handleSuccess}
            className="btnFacebook"
          >
            <Button variant='outlined' >Nueva cuenta</Button>
          </LoginButton>

        }
        {
          showPages &&
          <Button onClick={() => { setShowPages(false) }} variant='outlined' >Regresar</Button>


        }

        {
          showForms &&
          <Button onClick={() => { setShowForms(false); setShowPages(true) }} variant='outlined' >Regresar</Button>

        }
      </Breadcrumbs>
      <br />
      <Divider my={6} />

      <Loading open={isLoading} />
      <Grid
        container

        sx={{ padding: '20px', height: '80vh' }}
        alignItems="center"
        justify="center"
      >

        {(!showPages && !showForms) && <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={columns}
            rows={sessions}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar cuenta social'])}
            onDelete={(data) => { deleteSession(data); }}
            columnVisibilityModel={{

            }}
          />
        


        </Grid>
        }

        {showPages && <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={pageColumns}
            rows={currentPages}
            checkboxSelection
            showDelete={revisaPermisos(['Eliminar cuenta social'])}
            onDelete={(data) => { deletePage(data); }}
            columnVisibilityModel={{

            }}
          />

        </Grid>
        }

        {showForms && <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          align="center"
          sx={{ height: '100%' }}
        >
          <TablaUsuarios
            columns={formColumns}
            rows={currentForms}
            checkboxSelection
            showDelete={false}
            onDelete={(data) => { }}
            columnVisibilityModel={{

            }}
          />

        </Grid>
        }
      </Grid>
    


      <FormWebhookModal
        title={"Configuración del webhook"}
        open={openWebhookModal}
        setOpen={setOpenWebhookModal}

        webhookUrl={currentWebhookUrl}
        onSave={updateWebhookForForm}
      />


      <FormSyncModal
        forms={currentForms}
        pages={currentPages}
        onSave={updatePageStructure}
        open={openSyncForm}
        setOpen={setOpenSyncForm}

        title={"Sincronizar formulario"}
        setPageID={setCurrentPageID}
        userId={currentUserID}
      />
    </>
  );
}