import React, { useRef } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import api from '../../services/api';
import { Button, useTheme } from '@mui/material';

export default function SyncAccountButton({ onSelect }) {
    const theme = useTheme();
    const [accounts, setAccounts] = React.useState([]);
    const buttonRef = useRef(null); // Referencia al botón

    React.useEffect(() => {
        async function getAccounts() {
            try {
                const { data } = await api.post("/api/accounts");
                setAccounts(data.data);
            } catch (err) {
                console.error(err);
            }
        }
        getAccounts();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        onSelect(accounts[index]);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Función para calcular el ancho del botón
    const getButtonWidth = () => {
        if (buttonRef.current) {
            return buttonRef.current.offsetWidth;
        }
        return 0;
    };

    return (
        <div>
            <List component="nav" aria-label="Device settings" sx={{ bgcolor: 'background.paper' }}>
                <Button variant="contained" ref={buttonRef} onClick={handleClickListItem}>
                    Sincronizar formulario
                </Button>
            </List>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                    style: { minWidth: getButtonWidth(), background: theme.palette.secondary.main } // Establece el ancho del menú
                }}
            >
                {accounts.map((account, index) => (
                    <MenuItem
                        key={account.id}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        style={{color: theme.palette.primary.contrastText}}
                    >
                        {account.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
