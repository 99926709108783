import { useEffect, useState } from "react";
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';


const htmlContent=`
<!DOCTYPE html>
<html lang="es">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Factura</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
        }

        .outer-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            padding: 20px;
            box-sizing: border-box;
        }

        .container {
            width: 100%;
            max-width: 800px;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
        }

        header {
            border-bottom: 2px solid #333;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }

        .header-info,
        .client-info,
        .quote-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .header-info div,
        .client-info div,
        .quote-info div {
            flex: 1 1 45%;
            margin-bottom: 10px;
        }

        h1,
        h2,
        h3,
        h4,
        p {
            margin: 0;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            word-wrap: break-word;
        }

        table,
        th,
        td {
            border: 1px solid #333;
        }

        th,
        td {
            padding: 10px;
            text-align: left;
        }

        th {
            background-color: #f4f4f4;
        }

        .totals {
            text-align: right;
        }

        .totals td {
            border: none;
        }

        .notes {
            font-size: 0.9em;
            color: #555;
        }

        @media (max-width: 768px) {
            .header-info div,
            .client-info div,
            .quote-info div {
                flex: 1 1 100%;
            }

            .totals {
                text-align: left;
            }

            .totals table {
                width: 100%;
            }

            .totals td {
                display: block;
                width: 100%;
                text-align: right;
                padding-right: 0;
            }
        }

        @media (max-width: 600px) {
            table {
                display: block;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
            }
            th, td {
                white-space: nowrap;
            }
        }
    </style>
</head>

<body>
    <div class="outer-container">
        <div class="container">
            <header>
                <div class="header-info">
                    <div>
                        <h1>$mainCompany</h1>
                        <p>
                            $companyAddress<br>
                            Tel: $companyPhone<br>
                            Email: $companyEmail
                        </p>
                    </div>
                    <div style="text-align:right;">
                        <h2>$type</h2>
                        <p>Número de $typeLow: $number<br>
                            Fecha: $date<br>
                        </p>
                    </div>
                </div>
            </header>
            <section class="client-info">
                <div>
                    <h3>Cliente</h3>
                    <p>$clientName<br>
                        $clientCompany<br>
                        $clientAddress<br>
                        Tel: $clientPhone<br>
                        Email: $clientEmail
                    </p>
                </div>
            </section>
            <section class="quote-info">
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Aquí irían los elementos de la cotización -->
                    </tbody>
                </table>
            </section>
            <section class="totals">
                <table>
                    <tr>
                        <td>Subtotal:</td>
                        <td>$$subtotal</td>
                    </tr>
                    <tr>
                        <td>Impuesto ($taxesPercent%):</td>
                        <td>$$taxesAmount</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>$$total</td>
                    </tr>
                </table>
            </section>
            <section class="notes">
                <h4>Notas</h4>
                <p>Gracias por considerar nuestra oferta. Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros.</p>
            </section>
        </div>
    </div>
</body>

</html>

`
export default function QuotePreview({ externalQuote, externalCompany,products, html, type }) {
    const [quote, setQuote] = useState({});
    const [company, setCompany] = useState({});
    const [htmlTemplate, setHtmlTemplate] = useState(htmlContent);



    useEffect(() => {
        setQuote(externalQuote);
        setCompany(externalCompany);
       
        let htmlTemp = htmlContent.replace('$number', externalQuote.number);
        htmlTemp = htmlTemp.replace('$mainCompany', externalQuote.name);
        htmlTemp = htmlTemp.replace('$companyAddress', externalQuote.address);
        htmlTemp = htmlTemp.replace('$companyPhone', externalQuote.phone);
        htmlTemp = htmlTemp.replace('$companyEmail', externalQuote.email);
        htmlTemp = htmlTemp.replace('$subtotal', externalQuote.total);
   
        htmlTemp = htmlTemp.replace('$type', type);
        htmlTemp = htmlTemp.replace('$typeLow', type.toLowerCase());
        const taxesPercent =  externalQuote.taxes || 0;
        const taxesAmount = (taxesPercent * (externalQuote.total || 1))/100
        const total = parseFloat(externalQuote.total || 0 )+taxesAmount;
        htmlTemp = htmlTemp.replace('$taxesPercent', taxesPercent );
        htmlTemp = htmlTemp.replace('$taxesAmount', taxesAmount.toFixed(2));
        htmlTemp = htmlTemp.replace('$total', total.toFixed(2));
        htmlTemp = htmlTemp.replace('$clientName', externalCompany.clientName);
        htmlTemp = htmlTemp.replace('$clientCompany', externalCompany.name);
        htmlTemp = htmlTemp.replace('$clientAddress', externalCompany.address);
        htmlTemp = htmlTemp.replace('$clientPhone', externalCompany.phone);
        htmlTemp = htmlTemp.replace('$clientEmail', externalCompany.email);
        const fechaFormateada = format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: esLocale });
        htmlTemp = htmlTemp.replace('$date', fechaFormateada);

        products?.map((product) => {
    
            htmlTemp = addProduct(htmlTemp, product.name, product.description, product.quantity, product.unitPrice);
        })
        setHtmlTemplate(htmlTemp);

    }, [externalQuote, externalCompany, products])


    // Función para agregar un nuevo producto al string HTML
    function addProduct(html, name, descripcion, cantidad, precioUnitario) {
        // Construir el HTML para el nuevo producto

        let precioUnit = precioUnitario ?  parseFloat(precioUnitario) : 0;
        let cantidadTemp = cantidad ?  parseFloat(cantidad) : 1;
        const nuevoProductoHtml = `
        <tr>
            <td>${name}</td>
            <td>${descripcion}</td>
            <td>${cantidadTemp}</td>
            <td>$${parseFloat(precioUnit).toFixed(2)}</td>
            <td>$${(parseFloat(cantidadTemp) * parseFloat(precioUnit)).toFixed(2)}</td>
        </tr>
    `;

        // Encontrar el índice donde se debe insertar el nuevo producto
        const indice = html.indexOf('</tbody>');

        // Insertar el nuevo producto en el string HTML
        const nuevoHtml = html.slice(0, indice) + nuevoProductoHtml + html.slice(indice);

        return nuevoHtml;
    }

    // Función para eliminar un producto del string HTML
    function deleteProduct(html, indiceProducto) {
        // Encontrar el índice de inicio y fin del producto en la cadena de texto HTML
        const inicioProducto = html.indexOf('<tr>', indiceProducto);
        const finProducto = html.indexOf('</tr>', inicioProducto) + '</tr>'.length;

        // Eliminar el producto del string HTML
        const nuevoHtml = html.slice(0, inicioProducto) + html.slice(finProducto);

        return nuevoHtml;
    }

    return (
        <div style={{ overflow: 'auto' }}>
            <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
        </div>
    );

}