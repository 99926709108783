import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import './Tagging.css';
import Mensajes from '../../components/Mensajes/Mensajes';
import PantallaModal from '../../components/PantallaModal/ChildModal';

import { Button, Checkbox, Fab, FormControlLabel, Grid, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContactsIcon from '@mui/icons-material/Contacts';
import { CheckBox, Delete, Edit, Navigation } from '@mui/icons-material';
import api from '../../services/api';

import { SocketContext } from '../../services/socket';
import ContactsModal from '../ContactsModal';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import ContactsListModal from '../ContactsListModal';
import TemplateAddModal from '../TemplateAddModal';
import insertTextAtCursor from "insert-text-at-cursor";
import MultiSelect from '../MultiSelect';
import Loading from '../../components/Loading';
import FilesModal from '../FilesModal';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function Tagging({ theme, forwardingId, whatsapp, disableNav, contactsExternal = [], reloadMain, scheduled, isSequence, openContacts, setOpenContacts, useOldVersion }) {

  const textAreaRef = useRef(null);

  /**
   * Constante para navegar entre las vistas
   */
  const navigate = useNavigate();

  /**
  * Lista de los templates para insertar
  */
  const [templateList, setTemplateList] = useState([]);

  /**
  * Lista de los mensajes programables
  */
  const [sequences, setSequences] = useState([]);

  /**
   * Variable para mostrar la página de carga
   */
  const [isLoading, setIsLoading] = useState(true);

  const [userId, setUserId] = useState(0);

  /**  */
  const [sequenceID, setSequenceID] = useState(0);

  /** Lista de archivos */
  const [fileList, setFileList] = useState([]);

  /** Permite activar la programacion de archivos de manera local */
  const [localSchedule, setLocalSchedule] = useState(false);

  /** Permite guardar la plantilla en caso de necesitarlo */
  const [saveTamplate, setSaveTamplate] = useState(false);

  /**
   * Se ejecuta al inicio
   * Consulta permisos y valida los sistemas
   */
  useEffect(() => {
    setIsLoading(true);
    async function getPermissions() {

      try {

        const userInfo = await api.post("/api/getPermissions");

        setPermissions(userInfo.data.userInfo.permissions)
        setUserId(userInfo.data.userInfo.id);

        /**
         * Si no tiene los permisos entonces lo manda a la página de no permitidos
         */
        if (!revisaPermisos(['Envio Masivo'], userInfo.data.userInfo.permissions)) {
          //  navigate('/notAllowed')
        }

        const { data } = await api.post("api/getContacts", { sessions: userInfo.data.userInfo.wpsessionsNum });

        if (data.status == "Success") {
          setContactsList(data.contacts)
        } else {

        }

        /**
         * Consulta llas etiquetas
         */
        const dataTags = await api.post("/api/v2/getUserTags", { wpSessions: userInfo.data.userInfo.wpsessions });

        if (dataTags.data.status == "Success") {
          setTags(dataTags.data.tags)
          //console.log(dataTags.data.tags);
        }

        /**
         * COnsulta las plantillas
         */
        const dataTemplate = await api.post("/api/getTemplate", { wpSessions: userInfo.data.userInfo.wpsessions });

        if (dataTemplate.data.status == "Success") {
          setTemplateList(dataTemplate.data.messageTemplates)
          //console.log(dataTemplate.data.messageTemplates);
        }

      } catch (err) {
        console.log(err);
      }
      async function getSessions(){
        const { data } = await api.post("api/getSessions");
        setSessions(data.sessions);
      }
      async function getFileList() {
        try {
          const { data } = await api.post('/api/getLogFile');



          setFileList(data.files)
        } catch (err) {
          console.log(err);
        }
      }
      getFileList();
      getSessions();
      setIsLoading(false);

    }

    async function getSpeechs() {
      const { data } = await api.post("api/getSpeech");

      //console.log(data)
      setSpeechs(data.words);
    }

    async function getSequences() {
      const { data } = await api.post("/api/sequence/get");
      setSequences(data.scheduleSequenceList);
    }

    getPermissions();
    getSpeechs();
    getSequences();

    if (socket) {
      socket.on('MSG_SUCCESS', (id) => {
        //console.log(`Mensaje exitoso ${id} `);
      })
    }

  }, [])

  useEffect(() => {
    setLocalSchedule(scheduled);
  }, [scheduled])

  /**
    * Barra de notificaciones
    */
  const { enqueueSnackbar } = useSnackbar();

  /**Lsita de contactos
   */
  const [contactsList, setContactsList] = useState([])

  /**Lsita de etiqueTAS
  */
  const [tags, setTags] = useState([])

  /**
   * COntactos seleccionados
   */
  const [contactsSelectList, setContactsSelectList] = useState([])

 


  /**
  * Variables para abrir y cerrar e modal de contactos
  */
  const [openContactsList, setOpenContactsList] = useState(false);

  /**
   * Variable para contar cuantos speech se han agregado
   * Al inicio es un objeto
   */
  const [speechs, setSpeechs] = useState([]);


  /**
  * Variable para agregar los contactos
  * (objeto o array ? )
  */
  const [contacts, setContacts] = useState([]);

  /**
  * Variable de texto para los conatcos
  * 
  */
  const [contactsString, setContactsString] = useState('');

  /**
   * Pasa los permisos a todas las vistas
   */
  const [permissions, setPermissions] = useState([]);

  /**
   * Pasa las sesiones a todas vistas
   */
  const [sessions, setSessions] = useState([]);

  /**
   * La session (whatsapp) desde donde se va a enviar
   */
  const [selectedSession, setSelectedSession] = useState("");

  /**
   * El mensjae a enviar
   */
  const [message, setMessage] = useState("");

  

  /**
   * Socket para el envio masivo
   */
  const socket = useContext(SocketContext);


  const [currentTags, setCurrentTags] = useState([]);

  /**
   * Envia los mensajes masivos al socket
   */
  const tagMassive = async () => {

    /**
     * Array de contactos para enviar los mensajes
     * Integrantes del grupo que se creará
     */
    const constactsArr = [...contacts, ...contactsSelectList, ...contactsExternal];

    if (constactsArr.length == 0) {
      enqueueSnackbar(`Debe agregar al menos un número`, {
        variant: 'warning',
      });
      return;
    }

    if (!whatsapp && !selectedSession) {
      enqueueSnackbar(`Debe seleccionar un whatsapp`, {
        variant: 'warning',
      });
      return;
    }

    /**
     * Objeto para crear un grupo de envio (sin integrantes)
     */
    const request = {

      session: whatsapp ? whatsapp : selectedSession,
      phones: constactsArr,
      tags: currentTags
    }
    
    setIsLoading(true);
    try {


    
      const { data } = await api.post('/api/massive/tagging', request);

      if (data.status == "Success") {

        enqueueSnackbar(`Se etiquetaron ${data.count} contactos`, {
          variant: 'success',
        });

      } else {
        enqueueSnackbar(`Ocurrió un error al etiquetar los contactos`, {
          variant: 'error',
        });
      }


    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Ocurrió un error al etiquetar los contactos`, {
        variant: 'error',
      });
    }

    setIsLoading(false);

  }

  /**
   * Revisa el tipo de envio que se debe hacer
   */
  const checkSendType = () => {
    tagMassive();

  }
  /**
  * Compara la lista de permisos con los permisos asignados
  */
  const revisaPermisos = (allow, list = permissions, minMatch = 1) => {
    let count = 0;

    for (let i = 0; i < allow.length; i++) {
      for (let j = 0; j < list.length; j++) {
        // console.log(`${allow[i]}  == ${permissionsUser[j]}`);
        if (allow[i] == list[j]) {
          count++;
        }
      }
    }
    //  console.log(`${count} >= ${minMatch}`);
    // console.log(count >= minMatch);
    return count >= minMatch;
  }
  /**
   * Guarda los contactos y los convierte en un array
   * @param {*} contactsString 
   */
  const convertContacts = (contactsString) => {

    setContactsString(contactsString);
    setContacts(contactsString.split('\n'));
  }

  return (
    <>

      <Loading open={isLoading} />
      <div className="fondochat">
        <div className="chat">

          <Grid
            container

            spacing={2}
            alignItems="center"
            justify="center"
          >


            <Grid item sm={12} md={12} align="center">
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
                fontFamily='Radio Canada'
                color="white"
              >
                Etiquetado
              </Typography>
            </Grid>

            {!whatsapp &&
              <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Whatsapp"
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  variant="filled"
                  color="primary"

                  sx={{ width: '80%' }}
                // helperText="Elige un sistema desde el cual se enviaran los mensajes"
                >
                  {sessions.map((sessionWP) => (
                    <MenuItem key={sessionWP.id} value={sessionWP.id}>
                      {sessionWP.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <MultiSelect
                style={{ width: '80%' }}
                title="Etiquetas"
                theme={theme}
                list={tags}
                onChange={(data) => { setCurrentTags(data) }}
                initSelect={currentTags}

              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
              <Button
                color="success"
                variant="contained"
                component="label"

                sx={{ width: '80%' }}
                onClick={checkSendType}
              >
                Etiquetar
              </Button>

            </Grid>

            <Grid item sm={12} md={12} align="center">

              <ContactsModal contacts={contactsString} setContacts={convertContacts} open={openContacts} setOpen={setOpenContacts} />
              <ContactsListModal tagsList={tags} contacts={contactsList} setContacts={setContactsSelectList} open={openContactsList} setOpen={setOpenContactsList} />
            </Grid>

          </Grid>
        </div>
      </div>
    </>

  );
}

export default Tagging;